<template>
    <div v-for="dataItem in data" class="my-5">
        <h2 class="text-2xl font-bold mb-3" v-text="dataItem.title"/>

        <div class="space-y-4">
            <p v-for="item in dataItem.items" v-html="item"/>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
    props: {
        data: Array
    }
})
</script>
